import React, { Component } from "react"
import Page from "./Page"
import Pebble404 from "../components/Pebble404"

class Pebble404Page extends Component {
  getMetaData() {
    return {
      title: this.pageTitle(),
      meta: this.pageMeta(),
      link: this.pageLink(),
    }
  }

  pageTitle = () => {
    return "Page Not Found"
  }

  pageMeta = () => {
    return [
      {
        name: "description",
        content: "We were unable to find the page you are looking for.",
      },
    ]
  }

  pageLink = () => {
    return []
  }

  render() {
    return (
      <Page {...this.getMetaData()}>
        <Pebble404 />
      </Page>
    )
  }
}

export default Pebble404Page
