import React from "react"
import { Heading, Image, Text } from "@chakra-ui/react"
import AppDownloadButtons from "./AppDownloadButtons"
import Container from "./Container"
import Card from "./Card"
import AppLinks, { getMobileOS } from "./AppLinks"

const Pebble404 = () => {
  const mobileOS = getMobileOS()

  return (
    <Container isThin display="flex" flexDirection="column" alignItems="center">
      <Heading my={2} textAlign={"center"} as="h1" size="lg" width="80%">
        The page you are looking for either doesn’t exist or can only be
        accessed via the mobile app.{" "}
      </Heading>
      {typeof window !== "undefined" && (
        <Card
          width={"70%"}
          paddingTop={"70%"}
          my={5}
          overflow="hidden"
          variant="no-space"
        >
          <Image
            src="/images/404.jpg"
            alt="404"
            width="100%"
            height="100%"
            position={"absolute"}
            top={0}
            left={0}
          />
        </Card>
      )}
      <Text mb={2}>
        You may need to download the app in order to open the link that you
        clicked.
      </Text>
      <AppDownloadButtons />
      {!!mobileOS && (
        <Text my={2}>
          If the app is already installed on this device, click below to open
          the app.
        </Text>
      )}
      <AppLinks isHidingDownload />
    </Container>
  )
}

export default Pebble404
