import React, { useEffect } from "react"
import { Box, Button, Flex, Heading } from "@chakra-ui/react"
import { useRouter } from "next/router"

export function getMobileOS() {
  if (typeof navigator === "undefined") {
    return null
  }
  var userAgent = navigator.userAgent || navigator.vendor || window.opera

  if (/Instagram/i.test(userAgent)) {
    return "Instagram"
  }

  if (/android/i.test(userAgent)) {
    return "Android"
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS"
  }
  return null
}

const AppLinks = ({
  buttonTitle = null,
  downloadTitle = null,
  desktopError = null,
  isHidingDownload = false,
  isHidingButtons = false,
  isShowingLoginButton = false,
}) => {
  const router = useRouter()
  const mobileOS = getMobileOS()
  const isDisabled =
    typeof window !== "undefined" &&
    (window.location.pathname.includes("/login") ||
      window.location.pathname.includes("password") ||
      (window.location.pathname.includes("comp") &&
        !window.location.pathname.includes("register") &&
        !window.location.pathname.includes("invite")))

  // try to auto open app
  useEffect(() => {
    if (mobileOS && !isDisabled) {
      if (
        typeof navigator !== undefined &&
        navigator.userAgent.toLowerCase().indexOf("android") > -1
      ) {
        window.location.replace(
          `intent://www.pebbleclimbing.com${window.location.pathname}#Intent;scheme=https;package=com.pebbleclimbing.pebble;end`,
        )
      } else {
        window.location.href = `pebble:/${window.location.pathname}`
      }
    }
  }, [isDisabled, mobileOS])

  if (!mobileOS || isDisabled) {
    if (desktopError) {
      return (
        <>
          <Heading
            mb={0}
            as="h4"
            fontStyle={"italic"}
            size="md"
            variant="error"
            textAlign={"center"}
          >
            {desktopError}
          </Heading>
          <Flex justifyContent="center" alignItems="center">
            {isShowingLoginButton && (
              <Button
                variant="primary-shadow"
                size="md"
                mt={6}
                onClick={() => {
                  router.push({
                    pathname: "/login",
                    query: {
                      returnUrl: router.asPath,
                    },
                  })
                }}
              >
                Login
              </Button>
            )}
          </Flex>
        </>
      )
    }
    return null
  }

  if (isHidingButtons) {
    return null
  }

  return (
    <Box>
      {mobileOS ? (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          flexWrap={"wrap"}
        >
          <Button
            my={2}
            mr={1}
            variant="primary"
            onClick={() => {
              if (
                typeof navigator !== undefined &&
                navigator.userAgent.toLowerCase().indexOf("android") > -1
              ) {
                window.location.replace(
                  `intent://www.pebbleclimbing.com${window.location.pathname}#Intent;scheme=https;package=com.pebbleclimbing.pebble;end`,
                )
              } else {
                window.location.href = `pebble:/${window.location.pathname}`
              }
            }}
          >
            {buttonTitle ?? "View in the app"}
          </Button>
          {!isHidingDownload && (
            <Button
              my={2}
              ml={1}
              variant="secondary"
              onClick={() => {
                window.location.href =
                  mobileOS === "iOS"
                    ? "https://apps.apple.com/us/app/pebble-climbing/id1453943563"
                    : "https://play.google.com/store/apps/details?id=com.pebbleclimbing.pebble&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              }}
            >
              {downloadTitle ?? "Download the app"}
            </Button>
          )}
        </Box>
      ) : null}
    </Box>
  )
}

export default AppLinks
